<template>
  <!-- <div > -->
  <div class="framePage-scroll">
    <div class="ovy-a">
      <h3 style="padding-bottom: 20px">基本信息</h3>
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="120px"
        class
      >
        <el-form-item label="班级名称：" prop="projectName" class="form-item">
          <el-input
            v-model="Form.projectName"
            placeholder="请输入班级名称"
            :disabled="disabled"
            size="small"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="行政区划：" prop="areaId" class="form-item">
          <el-cascader
            v-model="Form.areaId"
            :options="areatreeList"
            :props="propsarea"
            :disabled="disabled"
            clearable
            filterable
            @change="areaChange"
            size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="有效日期：" prop="Effectivedate" class="form-item">
          <el-date-picker
            size="small"
            :disabled="disabledcc"
            v-model="Form.Effectivedate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="培训人数"
          prop="projectPeople"
          class="form-item"
          required
        >
          <el-input-number
            v-model="Form.projectPeople"
            @change="handleChange"
            :min="1"
            style="width: 100%"
            :disabled="disabled"
            size="small"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <div class="bottom-btn">
        <el-button @click="cancle" class="bgc-bv">取消</el-button>
        <el-button @click="seveData('Form')" class="bgc-bv" :disabled="disabled">保存</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import List from "@/mixins/List";
export default {
  name: "TestPaper",
  components: {
    // tree,
  },
  mixins: [UploadPic, DatePicker, List],
  data() {

    return {
      disabled: false,
      disabledcc: false,
      editJson: {},
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      Form: {
        projectId: "",
        projectName: "",
        areaId: "",
        Effectivedate: [],
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
      },
      // TrainingMethodsList: [],
      uploadFileList: [],
      ruleForm: {
        Trainingtype: "",
      },

      params: {},
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        projectPeople: [
          { required: true, message: "请输入培训人数", trigger: "blur" },
        ],

      },

      projectSource: "",

    };
  },
  created() {
    this.editJson = this.$route.query;
    this.projectId = sessionStorage.getItem("projectId");
    this.auditState = sessionStorage.getItem("auditState");
    this.projectSource = sessionStorage.getItem("projectSource");

    if (this.projectSource == "20") {
      this.disabled = true;
      this.disabledcc = true;
    }
    this.getareatree();
    if (
      this.auditState == "30" ||
      this.auditState == "20" ||
      this.auditState == "25"
    ) {
      this.disabled = true;
      this.disabledcc = true;
    }
    this.loadData(this.projectId);

    this.getCompanyInfo();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    // 获取鉴定机构信息
    getCompanyInfo(){
      this.$post("/biz/evaluate/company/info").then((ret) => {
        // console.log(ret)
        this.Form.projectUser = ret.data.compUser
        this.Form.projectUserPhone = ret.data.compPhone
      });
    },
    loadData(projectId) {
      this.$post("/biz/project/query", { projectId })
        .then((ret) => {
          let data = ret.data;
          let res = JSON.stringify(data);
          res = res.replace(/areaId/g, "value");
          res = res.replace(/areaName/g, "label");
          res = res.replace(/trainTypeId/g, "id");
          res = JSON.parse(res);
          this.id = res.id;
          this.selectType = res.idPath.substr(0, 1);
          this.Form = {
            projectId: res.projectId,
            projectName: res.projectName,
            Effectivedate: [res.startDateLong, res.endDateLong],
            areaId: res.value,
            projectUser: res.projectUser,
            projectUserPhone: res.projectUserPhone,
            projectPeople: res.projectPeople,
            projectSource: res.projectSource || "",
          };
          var timestamp = new Date().getTime();
          if (Number(res.endDateLong) > Number(timestamp)) {
            this.signEndDateDisabled = false;
          } else {
            this.signEndDateDisabled = true;
          }
          if (this.trainTypeIds.indexOf(this.params.trainFormId) != -1) {
            this.disableStu = true;
          } else {
            this.disableStu = false;
          }
        })
        .catch((err) => {
          return;
        });
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      const el1 = this.$refs.Form1;
      el.validate((valid) => {
        if (valid) {
          // 待审核30; 审核通过20; 25前后台都没有这个字段，别删(不敢动)
          if (
            this.auditState == "30" ||
            this.auditState == "20" ||
            this.auditState == "25"
          ) {
            this.preservation_1();
            // 否则走正常 - 下面的判断我也不知道啥意思!!!
          } else {
            if (this.Form.enableSurvey && this.params.trainFormId == "11") {
              el1.validate((valid1) => {
                if (valid1) {
                  this.doAjax();
                }
              });
            } else if (
              !this.Form.enableSurvey &&
              this.params.trainFormId == "11"
            ) {
              this.doAjax();
            } else if (
              this.Form.areaId.indexOf("130") != -1 &&
              this.params.trainFormId == "5"
            ) {
              if (this.Form.uploadfileList.length < 1) {
                this.$confirm("开班申请表未上传，是否上传？", "提示", {
                  confirmButtonText: "是",
                  cancelButtonText: "否",
                  showClose: false,
                  closeOnClickModal: false,
                  type: "warning",
                })
                  .then(() => {})
                  .catch(() => {
                    this.doAjax();
                  });
              } else {
                this.doAjax();
              }
            } else {
              this.doAjax();
            }
          }
        }
      });
    },
    // 添加
    doAjax() {
      let parmar = {
        projectId: this.Form.projectId,
        projectName: this.Form.projectName,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        signEndDate: this.Form.Effectivedate[1],
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        trainTypeId: '52',
      };
      if (this.Form.projectSource) {
        parmar.projectSource = this.Form.projectSource;
      }
      this.$post("/biz/project/saveOrUpdateCt", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            if (this.projectSource == "30") {
              this.$router.push({
                name: "sjz/jg/classList",
                params: {
                  refresh: true,
                  isAdd: false,
                },
              });
            } else {
              this.$router.push({
                name: "classListManage",
                params: {
                  refresh: true,
                  isAdd: false,
                },
              });
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.push({
        path: "/web/classListManage",
      });
    },
    // 保存 - 待审核30; 审核通过20
    preservation_1() {
      this.$post("/biz/project/update/printCertificateState", {
        projectId: this.Form.projectId,
        signEndDate: this.Form.Effectivedate[1],
        projectClassHourCertificate: true,
      })
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            if (this.projectSource == "30") {
              this.$router.push({
                name: "sjz/jg/classList",
                params: {
                  refresh: true,
                  isAdd: false,
                },
              });
            } else {
              this.$router.push({
                name: "classListManage",
                params: {
                  refresh: true,
                  isAdd: false,
                },
              });
            }
          }
        })
        .catch((err) => {
          return;
        });
    },
    //行政区划change事件
    areaChange(val) {
      if (val.indexOf("41") == -1) {
        this.Form.trainMethod = "10";
      } else {
        this.Form.trainMethod = "";
      }
    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-item {
    width: 45%;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.framePage-scroll /deep/ .upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
