<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="选择学员"
    top="1%"
    width="90%"
    :center="true"
    :before-close="tableBack"
  >
    <div class="CourseTableBox">
      <section class="CourseTable">
        <div class="operationControl">
          <div class="searchbox" style="padding: 0">
            <div title="招生信息" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">培训名称:</span>
              <el-select
                  size="small"
                  v-model="ctProjectId"
                  remote
                  :remote-method="getEnrollmentList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
              >
                <el-option
                    v-for="item in EnrollmentList"
                    :key="item.projectId"
                    :label="item.projectName"
                    :value="item.projectId"
                ></el-option>
              </el-select>
            </div>
            <div>
              <el-button class="bgc-bv" @click="search()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
              @selection-change="handleSelectionChange"
              :row-key="(row) => row.userId"

            >
              <el-table-column
                  type="selection"
                  :reserve-selection="true"
                  width="55">
              </el-table-column>
              <el-table-column
                label="学员姓名"
                align="center"
                show-overflow-tooltip
                prop="userName"
                minWidth="100"
              />
              <el-table-column
                  label="身份证号"
                  align="center"
                  show-overflow-tooltip
                  prop="idcard"
                  minWidth="160"
              />
              <el-table-column
                  label="手机号"
                  align="center"
                  show-overflow-tooltip
                  prop="mobile"
                  minWidth="120"
              />
              <el-table-column
                  label="报名审核状态"
                  align="center"
                  show-overflow-tooltip
                  prop="auditState"
                  minWidth="120"
              >
                <template slot-scope="scope">
                  <span>
                    {{ $setDictionary("CT_USER_AUDIT_STATE", scope.row.auditState) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                  label="报名日期"
                  align="center"
                  show-overflow-tooltip
                  prop="createTime"
                  minWidth="120"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </section>
      
    </div>
    <span class="dialog-footer">
      <div class="dialogBtn">
        <el-button @click="tableBack">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
<!--    <PaperShow ref="PaperShow" :modal="false" />-->
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty4.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import PaperShow from "@/views/resourse/popup/PaperShow";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";

export default {
  name: "SelectStudents",
  components: {
    Empty,
    PageNum,
    // PaperShow,
    // tree,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      dialogVisible: false,
      multipleSelection: [],

      projectId: '',
      ctProjectId: '',
      EnrollmentList: [],
      selectionKeys: [],

    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    // 多选选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
    },
    // 招生信息
    getEnrollmentList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/ct/project/ctProjectListNoPage", { projectName: query })
            .then((res) => {
              if (res.status == 0) {
                this.EnrollmentList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.EnrollmentList = [];
      }
    },
    tinit() {
      // this.getCourseOriginList();
      this.init();
      this.getTableHeight();
    },
    search(){
      if( !this.ctProjectId ){
        this.$message.error("请输入培训名称查询！");
      }else{
        this.$refs.multipleTable.clearSelection()
        console.log(this.multipleSelection)
        this.getData()
      }
    },
    getData(pageNum = 1) {

      this.projectId = sessionStorage.getItem("projectId");

      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        ctProjectId: this.ctProjectId || '',
      };

      this.doFetch({
        url: "/biz/projectUser/chooseTrainProjectUserList",
        params,
        pageNum,
      });
      // setTimeout(()=> {
      //   this.selectionKeys.forEach(key => {
      //     this.tableData.forEach(row => {
      //       if (row.userId== key.userId) {
      //         this.$refs.multipleTable.toggleRowSelection(row, true);
      //       }
      //     })
      //   })
      // },1000)
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.5) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      let _this = this
      if(!this.multipleSelection.length){
        this.$message.error("请至少选择一名学员！");
        return
      }else{
        let userIds = this.multipleSelection.map(item=>item.userId)
        let params = {
          projectId: this.projectId,
          userIds: userIds
        }
        this.$post("/biz/projectUser/chooseTrainProjectUser", params)
            .then((res) => {
              if (res.status == 0) {
                _this.$message({
                  message: "成功添加学员",
                  type: "success",
                });
                _this.tableBack()
              } else {
                _this.$message({
                  message: ret.message,
                  type: "error",
                });
              }
            })
            .catch(() => {
              return;
            });
      }
    },
    tableBack() {
      this.dialogVisible = false;
      this.ctProjectId = ''
      this.tableData = []
      this.apiData = {
        total: 0,
        size: 10,
        current: 1
      }
      this.EnrollmentList = [];
      this.selectionKeys = [];

      this.$parent.getData()

    },
    showPopUp() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="less">
.CourseTable {
  width: 100%;
  border: 1px solid #5c6be8;
  padding: 10px;
  border-radius: 0.5rem;
  .el-radio {
    .el-radio__label {
      display: none;
    }
  }
}
.testTime {
  // margin-left: 180px;
  padding-top: 20px;
  .itemLabel {
    display: inline-block;
    width: 130px;
    padding-bottom: 0.75rem;
    line-height: 2;
  }
  .testFomation {
    display: block;
    width: 300px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dialogBtn {
  button {
    width: 10rem;
  }
}
</style>
